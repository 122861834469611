import { render, staticRenderFns } from "./BuyDetail.vue?vue&type=template&id=f19f4236&scoped=true&"
import script from "./BuyDetail.vue?vue&type=script&lang=js&"
export * from "./BuyDetail.vue?vue&type=script&lang=js&"
import style0 from "./BuyDetail.vue?vue&type=style&index=0&id=f19f4236&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f19f4236",
  null
  
)

export default component.exports